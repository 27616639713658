import React from 'react';
import {
  H1,
  H2,
  H3,
  Pl,
  P,
  Box,
  WebsiteWrapper,
  FlexGrid,
  HR,
  ThemeProvider
} from '@parsleyhealth/cilantro-ui';
import ButtonWithLink from '../components/Buttons/button-with-link';
import Helmet from 'react-helmet';
import { Layout } from '../redesign';

import data from '../data/404-data';

interface Item {
  key: string;
  title: string;
  description: string;
  link: string;
  linkTitle: string;
}

interface Section {
  key: string;
  title: string;
  items: Item[];
}

const NotFoundPage: React.FunctionComponent = () => (
  <ThemeProvider>
    <Helmet title="Page not found | Parsley Health" />
    <Layout>
      <Box py={['l', 'l', 'l', 'xxl']} width="100%" bg="beige">
        <WebsiteWrapper>
          <FlexGrid>
            <Box
              display="flex"
              flexDirection="column"
              height="100%"
              justifyContent="center"
            >
              <H1 pb={['s', 's', 's', 'm']}>
                Oops! <br /> Page not found.
              </H1>
              <Pl>
                We can’t seem to find the page you’re looking <br /> for.
              </Pl>
            </Box>
            <Box>
              {data.map((section: Section) => (
                <Box>
                  <H2 pb={['s', 's', 's', 'm']} key={section.key}>
                    {section.title}
                  </H2>
                  {section.items.map((item: Item) => (
                    <Box key={item.key}>
                      <H3 pb="xs">{item.title}</H3>
                      <P pb={['s', 's', 's', 'xs']}>{item.description}</P>
                      <ButtonWithLink
                        destination={item.link}
                        variant="underlinedLink"
                        trackingName={`404 - ${item.title}`}
                      >
                        {item.linkTitle}
                      </ButtonWithLink>
                      <HR mt="m" bg="darkLineBorder" />
                    </Box>
                  ))}
                </Box>
              ))}
            </Box>
          </FlexGrid>
        </WebsiteWrapper>
      </Box>
    </Layout>
  </ThemeProvider>
);

export default NotFoundPage;
