export default [
  {
    key: 'helpful-links',
    title: 'Here are helpful links',
    items: [
      {
        key: 'homepage',
        title: 'Homepage.',
        description:
          'Explore our home page to learn about Parsley Health’s approach to medicine and how it will improve your health.',
        link: '/',
        linkTitle: 'Go to Home'
      },
      {
        key: 'plans-and-pricing',
        title: 'Plans & pricing.',
        description: 'Explore our plans page to see the plans & pricing',
        link: '/membership',
        linkTitle: 'Go to Plans & Pricing'
      },
      {
        key: 'our-doctors',
        title: 'Our doctors.',
        description:
          'Explore Our doctors page to learn more about Parsley’s doctors.',
        link: '/providers',
        linkTitle: 'Go to Our Doctors'
      }
    ]
  }
];
